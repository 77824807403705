import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _ffa3ae6a = () => interopDefault(import('../src/pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _a2afac92 = () => interopDefault(import('../src/pages/announcement/index.vue' /* webpackChunkName: "pages/announcement/index" */))
const _30182b98 = () => interopDefault(import('../src/pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _55e73e53 = () => interopDefault(import('../src/pages/frequently-asked-questions.vue' /* webpackChunkName: "pages/frequently-asked-questions" */))
const _3d95e7f4 = () => interopDefault(import('../src/pages/information-submit.vue' /* webpackChunkName: "pages/information-submit" */))
const _454feaf8 = () => interopDefault(import('../src/pages/lead-form.vue' /* webpackChunkName: "pages/lead-form" */))
const _5ca25695 = () => interopDefault(import('../src/pages/lead-form-blt.vue' /* webpackChunkName: "pages/lead-form-blt" */))
const _5c427259 = () => interopDefault(import('../src/pages/lead-form-newcar.vue' /* webpackChunkName: "pages/lead-form-newcar" */))
const _931a0dbe = () => interopDefault(import('../src/pages/lead-form-v2.vue' /* webpackChunkName: "pages/lead-form-v2" */))
const _62512242 = () => interopDefault(import('../src/pages/loading.vue' /* webpackChunkName: "pages/loading" */))
const _03f20d59 = () => interopDefault(import('../src/pages/loan-calculation.vue' /* webpackChunkName: "pages/loan-calculation" */))
const _0229d522 = () => interopDefault(import('../src/pages/loan-calculator.vue' /* webpackChunkName: "pages/loan-calculator" */))
const _241e81c7 = () => interopDefault(import('../src/pages/product-select.vue' /* webpackChunkName: "pages/product-select" */))
const _f8bfb2c0 = () => interopDefault(import('../src/pages/promotions/index.vue' /* webpackChunkName: "pages/promotions/index" */))
const _0398a450 = () => interopDefault(import('../src/pages/splash-page.vue' /* webpackChunkName: "pages/splash-page" */))
const _87de0a3e = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _2f1be19f = () => interopDefault(import('../src/pages/thank-you.vue' /* webpackChunkName: "pages/thank-you" */))
const _f9aa3bd4 = () => interopDefault(import('../src/pages/vehicle-search.vue' /* webpackChunkName: "pages/vehicle-search" */))
const _4acfe76a = () => interopDefault(import('../src/pages/vehicle-select.vue' /* webpackChunkName: "pages/vehicle-select" */))
const _6869a984 = () => interopDefault(import('../src/pages/belowtheline/4w-ka-prime.vue' /* webpackChunkName: "pages/belowtheline/4w-ka-prime" */))
const _70d1b146 = () => interopDefault(import('../src/pages/belowtheline/auto-one.vue' /* webpackChunkName: "pages/belowtheline/auto-one" */))
const _47d213fa = () => interopDefault(import('../src/pages/belowtheline/bquik-2024.vue' /* webpackChunkName: "pages/belowtheline/bquik-2024" */))
const _3466804a = () => interopDefault(import('../src/pages/belowtheline/campaign-dealer-refer.vue' /* webpackChunkName: "pages/belowtheline/campaign-dealer-refer" */))
const _3d5f57b2 = () => interopDefault(import('../src/pages/belowtheline/ddo-retention.vue' /* webpackChunkName: "pages/belowtheline/ddo-retention" */))
const _3af77242 = () => interopDefault(import('../src/pages/belowtheline/ddo-retention-line.vue' /* webpackChunkName: "pages/belowtheline/ddo-retention-line" */))
const _9d724bae = () => interopDefault(import('../src/pages/belowtheline/dealer-refer-mc.vue' /* webpackChunkName: "pages/belowtheline/dealer-refer-mc" */))
const _42f1aa9e = () => interopDefault(import('../src/pages/belowtheline/employee2w.vue' /* webpackChunkName: "pages/belowtheline/employee2w" */))
const _3c1e4822 = () => interopDefault(import('../src/pages/belowtheline/employee4w.vue' /* webpackChunkName: "pages/belowtheline/employee4w" */))
const _4d2506b4 = () => interopDefault(import('../src/pages/belowtheline/employee4w-2022-q4.vue' /* webpackChunkName: "pages/belowtheline/employee4w-2022-q4" */))
const _4dec60a9 = () => interopDefault(import('../src/pages/belowtheline/food-panda.vue' /* webpackChunkName: "pages/belowtheline/food-panda" */))
const _4d8a37cc = () => interopDefault(import('../src/pages/belowtheline/gobear.vue' /* webpackChunkName: "pages/belowtheline/gobear" */))
const _2ab9c0b3 = () => interopDefault(import('../src/pages/belowtheline/gpf-2w.vue' /* webpackChunkName: "pages/belowtheline/gpf-2w" */))
const _2e2371f1 = () => interopDefault(import('../src/pages/belowtheline/gpf-4w.vue' /* webpackChunkName: "pages/belowtheline/gpf-4w" */))
const _05dd10e9 = () => interopDefault(import('../src/pages/belowtheline/ka-the1.vue' /* webpackChunkName: "pages/belowtheline/ka-the1" */))
const _3505b197 = () => interopDefault(import('../src/pages/belowtheline/ka-true.vue' /* webpackChunkName: "pages/belowtheline/ka-true" */))
const _59f10244 = () => interopDefault(import('../src/pages/belowtheline/ka-true-rider.vue' /* webpackChunkName: "pages/belowtheline/ka-true-rider" */))
const _d8f8a5ba = () => interopDefault(import('../src/pages/belowtheline/kma-one-retail-x-ddo.vue' /* webpackChunkName: "pages/belowtheline/kma-one-retail-x-ddo" */))
const _6b7e36f9 = () => interopDefault(import('../src/pages/belowtheline/lalamove.vue' /* webpackChunkName: "pages/belowtheline/lalamove" */))
const _3b6baa32 = () => interopDefault(import('../src/pages/belowtheline/normal-plus.vue' /* webpackChunkName: "pages/belowtheline/normal-plus" */))
const _9f688c7c = () => interopDefault(import('../src/pages/belowtheline/pay-in-time.vue' /* webpackChunkName: "pages/belowtheline/pay-in-time" */))
const _b73fc1d2 = () => interopDefault(import('../src/pages/belowtheline/payroll.vue' /* webpackChunkName: "pages/belowtheline/payroll" */))
const _0739691b = () => interopDefault(import('../src/pages/belowtheline/referral.vue' /* webpackChunkName: "pages/belowtheline/referral" */))
const _8030cdce = () => interopDefault(import('../src/pages/belowtheline/revolving-x-gpf.vue' /* webpackChunkName: "pages/belowtheline/revolving-x-gpf" */))
const _459746a7 = () => interopDefault(import('../src/pages/belowtheline/shopee-food.vue' /* webpackChunkName: "pages/belowtheline/shopee-food" */))
const _c0998222 = () => interopDefault(import('../src/pages/belowtheline/susco.vue' /* webpackChunkName: "pages/belowtheline/susco" */))
const _dd9f815a = () => interopDefault(import('../src/pages/belowtheline/theone.vue' /* webpackChunkName: "pages/belowtheline/theone" */))
const _0cdf3898 = () => interopDefault(import('../src/pages/belowtheline/theone2w.vue' /* webpackChunkName: "pages/belowtheline/theone2w" */))
const _7bebecab = () => interopDefault(import('../src/pages/belowtheline/theonelifttime.vue' /* webpackChunkName: "pages/belowtheline/theonelifttime" */))
const _6286ffc7 = () => interopDefault(import('../src/pages/belowtheline/theoneline.vue' /* webpackChunkName: "pages/belowtheline/theoneline" */))
const _17a1c8d5 = () => interopDefault(import('../src/pages/belowtheline/true-twenty-years-q4.vue' /* webpackChunkName: "pages/belowtheline/true-twenty-years-q4" */))
const _1ab22a23 = () => interopDefault(import('../src/pages/belowtheline/welcomeback-ab.vue' /* webpackChunkName: "pages/belowtheline/welcomeback-ab" */))
const _a70c90f8 = () => interopDefault(import('../src/pages/contents/footer-contents.vue' /* webpackChunkName: "pages/contents/footer-contents" */))
const _601bcbe8 = () => interopDefault(import('../src/pages/product/big-bikes.vue' /* webpackChunkName: "pages/product/big-bikes" */))
const _1a6a2ed7 = () => interopDefault(import('../src/pages/product/car.vue' /* webpackChunkName: "pages/product/car" */))
const _653d3b99 = () => interopDefault(import('../src/pages/product/motorbike.vue' /* webpackChunkName: "pages/product/motorbike" */))
const _0c8f3bed = () => interopDefault(import('../src/pages/promotions/1day.vue' /* webpackChunkName: "pages/promotions/1day" */))
const _5c1b74b4 = () => interopDefault(import('../src/pages/promotions/20years.vue' /* webpackChunkName: "pages/promotions/20years" */))
const _4f398dea = () => interopDefault(import('../src/pages/promotions/apply-car-loan.vue' /* webpackChunkName: "pages/promotions/apply-car-loan" */))
const _821707d4 = () => interopDefault(import('../src/pages/promotions/apply-car-loan-23q3.vue' /* webpackChunkName: "pages/promotions/apply-car-loan-23q3" */))
const _5b6cd070 = () => interopDefault(import('../src/pages/promotions/auto-debit.vue' /* webpackChunkName: "pages/promotions/auto-debit" */))
const _34f4e4dc = () => interopDefault(import('../src/pages/promotions/bigbike100.vue' /* webpackChunkName: "pages/promotions/bigbike100" */))
const _5ca37be8 = () => interopDefault(import('../src/pages/promotions/bottle.vue' /* webpackChunkName: "pages/promotions/bottle" */))
const _b9ffc984 = () => interopDefault(import('../src/pages/promotions/configs-promotions/index.js' /* webpackChunkName: "pages/promotions/configs-promotions/index" */))
const _fc6886d4 = () => interopDefault(import('../src/pages/promotions/delivery.vue' /* webpackChunkName: "pages/promotions/delivery" */))
const _400653aa = () => interopDefault(import('../src/pages/promotions/direct-debit.vue' /* webpackChunkName: "pages/promotions/direct-debit" */))
const _13ed5ad6 = () => interopDefault(import('../src/pages/promotions/ew-protect.vue' /* webpackChunkName: "pages/promotions/ew-protect" */))
const _216d525e = () => interopDefault(import('../src/pages/promotions/giveaway-2024Q3.vue' /* webpackChunkName: "pages/promotions/giveaway-2024Q3" */))
const _13e76564 = () => interopDefault(import('../src/pages/promotions/keychain.vue' /* webpackChunkName: "pages/promotions/keychain" */))
const _3ecfac5c = () => interopDefault(import('../src/pages/promotions/member-get-member.vue' /* webpackChunkName: "pages/promotions/member-get-member" */))
const _81f7907e = () => interopDefault(import('../src/pages/promotions/movetocar4cash.vue' /* webpackChunkName: "pages/promotions/movetocar4cash" */))
const _61e1839d = () => interopDefault(import('../src/pages/promotions/necessary.vue' /* webpackChunkName: "pages/promotions/necessary" */))
const _72eb8bf3 = () => interopDefault(import('../src/pages/promotions/prochamber.vue' /* webpackChunkName: "pages/promotions/prochamber" */))
const _0e81ba53 = () => interopDefault(import('../src/pages/promotions/promptchai.vue' /* webpackChunkName: "pages/promotions/promptchai" */))
const _06532f53 = () => interopDefault(import('../src/pages/promotions/revolving-loan.vue' /* webpackChunkName: "pages/promotions/revolving-loan" */))
const _08ee4f28 = () => interopDefault(import('../src/pages/promotions/smile-uv.vue' /* webpackChunkName: "pages/promotions/smile-uv" */))
const _1f538516 = () => interopDefault(import('../src/pages/promotions/special-rate.vue' /* webpackChunkName: "pages/promotions/special-rate" */))
const _75339cac = () => interopDefault(import('../src/pages/promotions/tanglak90days.vue' /* webpackChunkName: "pages/promotions/tanglak90days" */))
const _625000ce = () => interopDefault(import('../src/pages/promotions/three-three-three.vue' /* webpackChunkName: "pages/promotions/three-three-three" */))
const _a67330aa = () => interopDefault(import('../src/pages/promotions/welcome-back-23q2.vue' /* webpackChunkName: "pages/promotions/welcome-back-23q2" */))
const _5064aca0 = () => interopDefault(import('../src/pages/purpose/business-expansion.vue' /* webpackChunkName: "pages/purpose/business-expansion" */))
const _3a264e7e = () => interopDefault(import('../src/pages/purpose/house-renovation.vue' /* webpackChunkName: "pages/purpose/house-renovation" */))
const _47bd541e = () => interopDefault(import('../src/pages/purpose/payoff-credit.vue' /* webpackChunkName: "pages/purpose/payoff-credit" */))
const _7e334107 = () => interopDefault(import('../src/pages/purpose/tuition-fee.vue' /* webpackChunkName: "pages/purpose/tuition-fee" */))
const _22aa7dc6 = () => interopDefault(import('../src/pages/contents/_categories/contents/index.js' /* webpackChunkName: "pages/contents/_categories/contents/index" */))
const _a71d40a4 = () => interopDefault(import('../src/pages/contents/_categories/_slug.vue' /* webpackChunkName: "pages/contents/_categories/_slug" */))
const _321e7b96 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/about-us",
      component: _ffa3ae6a,
      name: "about-us"
    }, {
      path: "/interest-rate-announcement",
      component: _a2afac92,
      props: true,
      name: "announcement"
    }, {
      path: "/contact-us",
      component: _30182b98,
      name: "contact-us"
    }, {
      path: "/frequently-asked-questions",
      component: _55e73e53,
      name: "frequently-asked-questions"
    }, {
      path: "/information-submit",
      component: _3d95e7f4,
      name: "information-submit"
    }, {
      path: "/lead-form",
      component: _454feaf8,
      name: "lead-form"
    }, {
      path: "/lead-form-blt",
      component: _5ca25695,
      name: "lead-form-blt"
    }, {
      path: "/lead-form-newcar",
      component: _5c427259,
      name: "lead-form-newcar"
    }, {
      path: "/lead-form-v2",
      component: _931a0dbe,
      name: "lead-form-v2"
    }, {
      path: "/loading",
      component: _62512242,
      name: "loading"
    }, {
      path: "/loan-calculation",
      component: _03f20d59,
      name: "loan-calculation"
    }, {
      path: "/loan-calculator",
      component: _0229d522,
      name: "loan-calculator"
    }, {
      path: "/product-select",
      component: _241e81c7,
      name: "product-select"
    }, {
      path: "/promotions",
      component: _f8bfb2c0,
      name: "promotions"
    }, {
      path: "/splash-page",
      component: _0398a450,
      name: "splash-page"
    }, {
      path: "/terms-and-conditions",
      component: _87de0a3e,
      name: "terms-and-conditions"
    }, {
      path: "/thank-you",
      component: _2f1be19f,
      name: "thank-you"
    }, {
      path: "/vehicle-search",
      component: _f9aa3bd4,
      name: "vehicle-search"
    }, {
      path: "/vehicle-select",
      component: _4acfe76a,
      name: "vehicle-select"
    }, {
      path: "/promotions-btl-campaign-4w-ka-prime",
      component: _6869a984,
      props: true,
      name: "belowtheline-4w-ka-prime"
    }, {
      path: "/promotions-auto1-the-best-car-loan-lowest-rate",
      component: _70d1b146,
      props: true,
      name: "belowtheline-auto-one"
    }, {
      path: "/promotions/c4c-b-quik",
      component: _47d213fa,
      props: true,
      name: "belowtheline-bquik-2024"
    }, {
      path: "/promotions-BTL-Campaign-Dealer-Refer",
      component: _3466804a,
      props: true,
      name: "belowtheline-campaign-dealer-refer"
    }, {
      path: "/promotions-btl-normal-ptt",
      component: _3d5f57b2,
      props: true,
      name: "belowtheline-ddo-retention"
    }, {
      path: "/promotions-btl-normal-ptt-line",
      component: _3af77242,
      props: true,
      name: "belowtheline-ddo-retention-line"
    }, {
      path: "/promotions-btl-campaign-dealer-refer-mc",
      component: _9d724bae,
      props: true,
      name: "belowtheline-dealer-refer-mc"
    }, {
      path: "/promotions-BTL-Campaign-2w-central-employee",
      component: _42f1aa9e,
      props: true,
      name: "belowtheline-employee2w"
    }, {
      path: "/promotions-BTL-Campaign-4w-central-employee",
      component: _3c1e4822,
      props: true,
      name: "belowtheline-employee4w"
    }, {
      path: "/belowtheline/employee4w-2022-q4",
      component: _4d2506b4,
      name: "belowtheline-employee4w-2022-q4"
    }, {
      path: "/promotions-BTL-Food-Panda-C4C",
      component: _4dec60a9,
      props: true,
      name: "belowtheline-food-panda"
    }, {
      path: "/promotions-BTL-Campaign-gobear",
      component: _4d8a37cc,
      props: true,
      name: "belowtheline-gobear"
    }, {
      path: "/promotions-BTL-Campaign-GPF-2W",
      component: _2ab9c0b3,
      props: true,
      name: "belowtheline-gpf-2w"
    }, {
      path: "/promotions-BTL-Campaign-GPF-4W",
      component: _2e2371f1,
      props: true,
      name: "belowtheline-gpf-4w"
    }, {
      path: "/promotions-btl-campaign-c4c-ka-the1",
      component: _05dd10e9,
      props: true,
      name: "belowtheline-ka-the1"
    }, {
      path: "/promotions-btl-campaign-true-2024",
      component: _3505b197,
      props: true,
      name: "belowtheline-ka-true"
    }, {
      path: "/promotions-btl-campaign-true-rider-2024",
      component: _59f10244,
      props: true,
      name: "belowtheline-ka-true-rider"
    }, {
      path: "/promotions-BTL-C4C-KMA-4W",
      component: _d8f8a5ba,
      props: true,
      name: "belowtheline-kma-one-retail-x-ddo"
    }, {
      path: "/promotions-BTL-Lalamove-C4C",
      component: _6b7e36f9,
      props: true,
      name: "belowtheline-lalamove"
    }, {
      path: "/promotions-btl-campaign-normal-plus",
      component: _3b6baa32,
      props: true,
      name: "belowtheline-normal-plus"
    }, {
      path: "/promotions-BTL-pay-in-time-get-cash-back",
      component: _9f688c7c,
      props: true,
      name: "belowtheline-pay-in-time"
    }, {
      path: "/promotions-btl-bay-payroll-4w",
      component: _b73fc1d2,
      props: true,
      name: "belowtheline-payroll"
    }, {
      path: "/promotions-BTL-Campaign-StaffRefer",
      component: _0739691b,
      props: true,
      name: "belowtheline-referral"
    }, {
      path: "/promotions-BTL-revolving-loan-gpf",
      component: _8030cdce,
      props: true,
      name: "belowtheline-revolving-x-gpf"
    }, {
      path: "/promotions-btl-campaign-2w-shopeerider",
      component: _459746a7,
      props: true,
      name: "belowtheline-shopee-food"
    }, {
      path: "/promotions-btl-campaign-susco",
      component: _c0998222,
      props: true,
      name: "belowtheline-susco"
    }, {
      path: "/promotions-BTL-Campaign-TheOne",
      component: _dd9f815a,
      props: true,
      name: "belowtheline-theone"
    }, {
      path: "/promotions-BTL-Campaign-TheOne2W",
      component: _0cdf3898,
      props: true,
      name: "belowtheline-theone2w"
    }, {
      path: "/promotions-btl-extension-the1",
      component: _7bebecab,
      props: true,
      name: "belowtheline-theonelifttime"
    }, {
      path: "/promotions-btl-extension-the1-line",
      component: _6286ffc7,
      props: true,
      name: "belowtheline-theoneline"
    }, {
      path: "/promotions-btl-truec4c",
      component: _17a1c8d5,
      props: true,
      name: "belowtheline-true-twenty-years-q4"
    }, {
      path: "/promotions-btl-welcome-back-selected",
      component: _1ab22a23,
      props: true,
      name: "belowtheline-welcomeback-ab"
    }, {
      path: "/contents/footer-contents",
      component: _a70c90f8,
      name: "contents-footer-contents"
    }, {
      path: "/product/big-bikes",
      component: _601bcbe8,
      name: "product-big-bikes"
    }, {
      path: "/product/car",
      component: _1a6a2ed7,
      name: "product-car"
    }, {
      path: "/product/motorbike",
      component: _653d3b99,
      name: "product-motorbike"
    }, {
      path: "/promotions/1-day",
      component: _0c8f3bed,
      props: true,
      name: "promotions-1day"
    }, {
      path: "/promotions/c4c-20years-2W-24q3",
      component: _5c1b74b4,
      props: true,
      name: "promotions-20years"
    }, {
      path: "/promotions/apply-car-loan-get-premium-tumbler",
      component: _4f398dea,
      props: true,
      name: "promotions-apply-car-loan"
    }, {
      path: "/promotions/apply-car-loan-get-usb-cable",
      component: _821707d4,
      props: true,
      name: "promotions-apply-car-loan-23q3"
    }, {
      path: "/promotions/apply-auto-debit-get-the1-points",
      component: _5b6cd070,
      props: true,
      name: "promotions-auto-debit"
    }, {
      path: "/promotions/2W-big-bike-loan",
      component: _34f4e4dc,
      props: true,
      name: "promotions-bigbike100"
    }, {
      path: "/promotions/apply-car-loan-get-foldable-bottle",
      component: _5ca37be8,
      props: true,
      name: "promotions-bottle"
    }, {
      path: "/promotions/configs-promotions",
      component: _b9ffc984,
      name: "promotions-configs-promotions"
    }, {
      path: "/promotions/free-delivery-contract-at-home",
      component: _fc6886d4,
      props: true,
      name: "promotions-delivery"
    }, {
      path: "/promotions/direct-debit",
      component: _400653aa,
      props: true,
      name: "promotions-direct-debit"
    }, {
      path: "/promotions/4w-protect-auto-parts",
      component: _13ed5ad6,
      props: true,
      name: "promotions-ew-protect"
    }, {
      path: "/promotions/new-customer-giveaway",
      component: _216d525e,
      props: true,
      name: "promotions-giveaway-2024Q3"
    }, {
      path: "/promotions/apply-car-loan-get-premium-keychain",
      component: _13e76564,
      props: true,
      name: "promotions-keychain"
    }, {
      path: "/promotions/member-get-member",
      component: _3ecfac5c,
      props: true,
      name: "promotions-member-get-member"
    }, {
      path: "/promotions/move-to-car4cash",
      component: _81f7907e,
      props: true,
      name: "promotions-movetocar4cash"
    }, {
      path: "/promotions/c4c-necessary",
      component: _61e1839d,
      props: true,
      name: "promotions-necessary"
    }, {
      path: "/promotions/c4c-thai-chamber",
      component: _72eb8bf3,
      props: true,
      name: "promotions-prochamber"
    }, {
      path: "/promotions/c4c-promptchai-rehit-22",
      component: _0e81ba53,
      props: true,
      name: "promotions-promptchai"
    }, {
      path: "/promotions/car-loan-revolving-campaign",
      component: _06532f53,
      props: true,
      name: "promotions-revolving-loan"
    }, {
      path: "/promotions/car-loan-lowest-rate-and-easier-approval",
      component: _08ee4f28,
      props: true,
      name: "promotions-smile-uv"
    }, {
      path: "/promotions/c4c-app-in-get-special-rate",
      component: _1f538516,
      props: true,
      name: "promotions-special-rate"
    }, {
      path: "/promotions/c4c-tanglak-90days",
      component: _75339cac,
      props: true,
      name: "promotions-tanglak90days"
    }, {
      path: "/promotions/c4c-333",
      component: _625000ce,
      props: true,
      name: "promotions-three-three-three"
    }, {
      path: "/promotions/23Q2-welcome-back-apply-car-loan-get-extra-the1-point",
      component: _a67330aa,
      props: true,
      name: "promotions-welcome-back-23q2"
    }, {
      path: "/business-expansion",
      component: _5064aca0,
      props: true,
      name: "purpose-business-expansion"
    }, {
      path: "/home-decoration",
      component: _3a264e7e,
      props: true,
      name: "purpose-house-renovation"
    }, {
      path: "/clear-credit-card-debt",
      component: _47bd541e,
      props: true,
      name: "purpose-payoff-credit"
    }, {
      path: "/educational-payment",
      component: _7e334107,
      props: true,
      name: "purpose-tuition-fee"
    }, {
      path: "/contents/:categories?/contents",
      component: _22aa7dc6,
      name: "contents-categories-contents"
    }, {
      path: "/contents/:categories?/:slug?",
      component: _a71d40a4,
      name: "contents-categories-slug"
    }, {
      path: "/",
      component: _321e7b96,
      name: "index"
    }, {
      path: "/promotions-BTL-Campaign-TelesalesRefer",
      component: _0739691b,
      props: true,
      name: "belowtheline-referral1"
    }, {
      path: "/promotions-BTL-Campaign-KFCRefer",
      component: _0739691b,
      props: true,
      name: "belowtheline-referral2"
    }, {
      path: "/promotions-BTL-Campaign-SCGRefer",
      component: _0739691b,
      props: true,
      name: "belowtheline-referral3"
    }, {
      path: "/promotions-BTL-Campaign-HomeproRefer",
      component: _0739691b,
      props: true,
      name: "belowtheline-referral4"
    }, {
      path: "/promotions-BTL-Campaign-PLSARefer",
      component: _0739691b,
      props: true,
      name: "belowtheline-referral5"
    }, {
      path: "/promotions-BTL-Campaign-MortgageRefer",
      component: _0739691b,
      props: true,
      name: "belowtheline-referral6"
    }, {
      path: "/promotions-BTL-Campaign-PLSARefer-2w",
      component: _0739691b,
      props: true,
      name: "belowtheline-referral7"
    }, {
      path: "/promotions-BTL-Campaign-MortgageRefer-2w",
      component: _0739691b,
      props: true,
      name: "belowtheline-referral8"
    }],

    fallback: false
  })
}
